import React, { useState, useContext, useEffect } from 'react';
import { singleContent, animScroll } from '../functions';
import Title from './TitleContent';
import AppContext from '../AppContext';


function Skills() {
    const { lang } = useContext(AppContext);
    // const [classItem, setClassItem] = useState('');
    // const [deg, setDeg] = useState(0);
    const [data, setData] = useState({});
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'page', id: 2 }));
    }
    function skillContent() {
        if (data.hasOwnProperty('content') && (data.content.hasOwnProperty('in'))) {
            return (
                data.content.in.map((skill) => {
                    return (
                        <React.Fragment key={skill.skillName}>
                            <div className='skill-title font26'>{skill.skillName}</div>
                            <div className='skill-cont'>
                                <div className='progress' style={{ width: `${skill.progress}%` }}>
                                    <div className='anim-cont'>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })
            );
        }
    }
    useEffect(() => {
        getData();
        const areaListener = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-cont'],
            changeClass: '.fade-width',
            areaListener: areaListener,
            kill: false
        });
        const areaListener1 = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-center'],
            changeClass: '.fade',
            areaListener: areaListener1,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener,
                kill: true
            });
            animScroll({
                areaListener: areaListener1,
                kill: true
            });
        }
    }, [lang]);
    return (
        <>
            {Object.keys(data).length ?
                <section className='skills'>                  
                    <div className='grid'>                       
                       <Title data={data.content.title}/>
                        {skillContent()}
                    </div>                   
                </section> : ""
            }
        </>
    );
}

export default Skills;