import React, { useState, useEffect, useContext, useRef } from 'react';
import SingleBox from './SingleBox';
import AppContext from '../AppContext';
import { useSwipeable } from 'react-swipeable';

function SliderHome() {
    const { homeSliderRender } = useContext(AppContext);
    const [slides, setSlides] = useState('');
    const [amountSlides, setAmountSlides] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);
    const handlers = useSwipeable({
        // onSwiped: (eventData) => console.log("User Swiped!", eventData),
        onSwipedLeft: () => handleClickNext(),
        onSwipedRight: () => handleClickPrev(),
        delta: 20,
    });

    function handleClickPrev() {
        let copySLides = [...slides];
        let copyCurrentSlide = currentSlide;
        if (copySLides.length) {
            if (copyCurrentSlide !== 0) {
                copyCurrentSlide--;
                copySLides.forEach((element) => {
                    element.classList.remove('prev-el');
                    element.classList.remove('nx-el');
                    element.classList.remove('current-el');
                });
                copySLides[copyCurrentSlide].classList.add('current-el');
                copySLides[copyCurrentSlide + 1].classList.add('nx-el');
                if (copyCurrentSlide !== 0) {
                    copySLides[copyCurrentSlide - 1].classList.add('prev-el');
                }
                setCurrentSlide(copyCurrentSlide);
            }
        } else homeSlider();
    }
    function handleClickNext() {
        let copySLides = [...slides];
        let copyCurrentSlide = currentSlide;
        if (copySLides.length) {
            if (copyCurrentSlide !== amountSlides - 1) {
                copyCurrentSlide++;
                copySLides.forEach((element) => {
                    element.classList.remove('prev-el');
                    element.classList.remove('nx-el');
                    element.classList.remove('current-el');
                });
                copySLides[copyCurrentSlide].classList.add('current-el');
                copySLides[copyCurrentSlide - 1].classList.add('prev-el');
                if (copyCurrentSlide !== amountSlides - 1) {
                    copySLides[copyCurrentSlide + 1].classList.add('nx-el');
                }
                setCurrentSlide(copyCurrentSlide);
            }
        } else homeSlider();
    }
    function homeSlider() {
        setTimeout(() => {
            let elements = [...sliderRef.current.querySelectorAll('.single-project')];
            let amuntElements = elements.length;
            let minHeight = 0;
            elements.forEach(el => {
                minHeight = Math.max(minHeight, el.scrollHeight);
            });
            elements.forEach(el => {
                el.style.minHeight = minHeight + 'px';
            });
            if (amuntElements) {
                setSlides(elements);
                setAmountSlides(amuntElements);
                elements[1].classList.add('nx-el');
                // console.log([sliderRef.current.children[0].children]);
                // console.log(amuntElements);
            }
        }, 5);

    }
    useEffect(() => {
        if (homeSliderRender) {
            homeSlider();
        }
    }, [homeSliderRender]);
    return (
                <section className='section slider-home' ref={sliderRef}>
                    <div className='container-slider-home custom-slider' {...handlers}>
                        <div className={`arr-prev ${currentSlide === 0 ? 'disabled' : ""}`} onClick={handleClickPrev}> <img src="files/images/arr-left.svg" alt="" /> </div>
                        <div className='slider-content'>
                            <SingleBox
                                activeCat='all'
                                animation={false}
                            />
                        </div>
                        <div className={`arr-next ${currentSlide === amountSlides - 1 ? 'disabled' : ""}`} onClick={handleClickNext}><img src="files/images/arr-right.svg" alt="" /></div>
                    </div>
                </section>            
    );
}

export default SliderHome;