import React, {useState , useEffect , useContext} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {singleContent, animScroll} from '../functions';
import AppContext from '../AppContext';
import Title from './TitleContent';

function SingleProject() {
    let [searchParams, setSearchParams] = useSearchParams();
    let [id , setId] = useState(searchParams.get('id'));
    let [data, setData] = useState({});
    const navigate = useNavigate();
    const { lang } = useContext(AppContext);
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'project', id:id }));
    }
    const returnHome = () =>{
        navigate({
            pathname: "/projects"           
        });
    }
    useEffect(() => {     
        getData();
        const areaListener = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-center'],
            changeClass: '.fade',
            areaListener: areaListener,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener,
                kill: true
            });
        }
    }, [lang])
    return (
        <>
            {data === undefined ? returnHome() :
            (
                (Object.keys(data).length) ?
                <section className='section section-single-project'>
                    <div className='grid'>                       
                        { <Title data={data.content.title} />}
                        <div className='single-project-container'><span dangerouslySetInnerHTML={{ __html: data.content.html }}></span></div>
                    </div>
                </section> :""                
            )
            }            
        </>
    );
}

export default SingleProject;