import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppContext from './AppContext.js';
import './style.css';

import Navigation from './Navigation';
import MainMenu from './MainMenu';
import Footer from './Footer.js';
function App() {
    const [lang, setLang] = useState('PL');
    const [homeSliderRender, setHomeSliderRender] = useState(false);     
    // useEffect(() => {
    //     console.log(homeSliderRender)
    // }, [homeSliderRender]);
    return (
        <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>            
            <AppContext.Provider value={{
                lang:lang,
                setLang:setLang,
                homeSliderRender:homeSliderRender,
                setHomeSliderRender:setHomeSliderRender
                // data:data,
                // setData:setData
            }}>
                <nav><Navigation /></nav>
                <main id="menu"><MainMenu /></main>
                <footer id="footer"><Footer/></footer>
                {/* <a name="menu"></a> */}
            </AppContext.Provider>
        </BrowserRouter>
    );
}

export default App;
