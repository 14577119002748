import React from 'react';
import { NavLink } from 'react-router-dom';

function TextLink({ data }) {
    function chandleclickMenu(event) {
        const menu = document.querySelector("#menu");
        const header = document.querySelector(".header");
        window.scrollTo(0, menu.offsetTop - header.clientHeight);
    }
    return (
        <section className='TextLink anim-center'>
            <h3 className='normal'>
                {data.desc}
            </h3>
            {
                data.link ?
                    <NavLink to={`/${data.link}`} className='plus-button' onClick={chandleclickMenu}>
                        <div className='element'>
                            <span>{data.linkName}
                            </span>
                        </div>
                    </NavLink> : ""
            }
        </section>
    );
}

export default TextLink;