import React, { useState, useContext, useEffect } from 'react';
import { listCategory, singleContent, animScroll } from '../functions';
import Title from './TitleContent';
import AppContext from '../AppContext';
import SingleBox from './SingleBox';


function Projects() {
    const { lang } = useContext(AppContext);
    const [data, setData] = useState({});
    const [cat, setCat] = useState([]);
    const [activeCat, setActiveCat] = useState("all");
    const getCat = async () => {
        setCat(await listCategory({ lang: lang, type: 'project', order: 'name' }));
    }
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'page', id: 1 }));
    }
    function chandleclickCategory(event) {
        const menu = document.querySelector("#menu");
        const header = document.querySelector(".header");
        setTimeout(() => {
            window.scrollTo(0, menu.offsetTop - header.clientHeight);
        }, 40);
    }   
    const allCat = () => {
        if (Object.keys(data).length > 0 && cat.length > 0) {
            let name = "";
            let elClass = "";
            if (data.content.hasOwnProperty('allCatName') && (data.content.allCatName)) {
                name = data.content.allCatName;
                if (activeCat === 'all') {
                    elClass = 'active';
                }
            }
            let result = cat.map((element) => {
                let catClass = "";
                if (activeCat === element) {
                    catClass = 'active';
                }
                return (
                    <div className={`singleCat ${catClass}`} key={element} onClick={() => { setActiveCat(element); chandleclickCategory() }}>{element}</div>
                )
            })
            return (
                <>
                    <div className={`singleCat ${elClass}`} onClick={() => { setActiveCat('all'); chandleclickCategory() }}>{name}</div>
                    {result}
                </>
            )
        }
    }
    useEffect(() => {
        getCat();
        getData();
        setActiveCat('all')
        const areaListener = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-center'],
            changeClass: '.fade',
            areaListener: areaListener,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener,
                kill: true
            });
        }
    }, [lang]);
    return (
        <>
            {Object.keys(data).length ?
                <section className='section projects'>
                    <div className='grid'>
                        <Title data={data.content.title} />
                        <div className='cat-container font26 anim-center'>
                            {allCat()}
                        </div>
                        <div className='projects-container'>
                            <SingleBox
                                activeCat={activeCat}
                                animation={true}
                            />
                        </div>
                    </div>
                </section> : ""}
        </>
    );
}

export default Projects;