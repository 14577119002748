import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
    const [svgContent, setSvgContent] = useState();
    function yearActual() {
        const date = new Date();
        return (
            <span>{`${date.getFullYear()}${"\u00A0"}`}</span>
        )
    }
    const getSvg = async () => {
        let dataCount = await fetch("files/images/logo.svg").catch(() => console.log('error'));
        let result = await dataCount.text();      
        setSvgContent(result);       
    }
    function chandleclickScroll() {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        getSvg();
    }, [svgContent])
    return (
        <section className='footer-section'>
            <div className='container'>
                <div className='copyRights '>
                    <NavLink to='/' className={``} onClick={chandleclickScroll}>
                        <div className='cont-img'>
                            <span dangerouslySetInnerHTML={{ __html: svgContent }}></span>
                        </div>
                    </NavLink>
                    <span><a href="mailto:kslukasz@o2.pl" className='plus-button'>kslukasz@o2.pl</a></span>
                    <span className='rights'>copyright © {yearActual()}ondev.pl</span>
                </div>
            </div>
        </section>
    );
}

export default Footer;