import React, { useEffect, useContext, useState } from 'react';
import SliderHome from './SliderHome';
import { singleContent, animScroll } from '../functions';
import Title from './TitleContent';
import Quote from './Quote';
import AppContext from '../AppContext';
import SliderTech from './SliderTech';
import StrongPoints from './StrongPoints';
import TextLink from './TextLink';

function Home() {
    const { lang } = useContext(AppContext);
    // const [classItem, setClassItem] = useState('');
    // const [deg, setDeg] = useState(0);
    const [data, setData] = useState({});
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'page', id: 5 }));
    }
    useEffect(() => {
        getData();

    }, [lang]);
    useEffect(() => {
        const areaListener1 = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-center', '.anim-quote'],
            changeClass: '.fade',
            areaListener: areaListener1,
            kill: false
        });
        const areaListener2 = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.25,
            classArray: ['.anim-left'],
            changeClass: '.fade',
            areaListener: areaListener2,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener1,
                kill: true
            });
            animScroll({
                areaListener: areaListener2,
                kill: true
            });
        }
    })
    return (
        <>

            {Object.keys(data).length ?
                <>
                    <Quote data={data} />
                    <Title data={data.content.title} />
                    <SliderHome />
                    <Title data={data.content.boxTitle} />
                    <StrongPoints data={data.content.boxContent} />
                    <Title data={data.content.link1.title} />
                    <TextLink data={data.content.link1} />
                    <Title data={data.content.sliderTitle} />
                    <SliderTech data={data} />
                </>
                : ""
            }
        </>
    );
}

export default Home;