import React, { useState, useContext, useEffect } from 'react';
import { singleContent, animScroll } from '../functions';
import Title from './TitleContent';
import AppContext from '../AppContext';

function About() {
    const { lang } = useContext(AppContext);
    const [data, setData] = useState({});
    const [mail,setMail] = useState("");
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'page', id: 4 }));
    }
    function copyToClip(event) {
        const text = ((event.target).parentNode).querySelector('a').textContent;
        const clipBoard = navigator.clipboard;
        clipBoard.writeText(text).then(() => {
           setMail(text);
        });
    }
    function contact() {
        const mails = data.content.mail.map(element => {
            return (
                <div className='mail-container' key={element}>
                    <a href={`mailto:${element}`} >{element}</a>
                    <img src="files/images/copyico.svg" alt="" onClick={(event) => copyToClip(event)} className={`${mail !== element ? "" : "displayNone"}`}/>
                    <img src="files/images/copyico-blue.svg" alt="" className={`${mail === element ? "" : "displayNone"}`}/>
                </div>
            )
        })
        return (
            <section className='about font26'>
                <div className='desc'>
                    {data.content.desc}
                    {mails}
                </div>
                <div className='name'>
                    {data.content.name}
                </div>
            </section>
        );
    }
    useEffect(() => {
        getData();
        const areaListener = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-center'],
            changeClass: '.fade',
            areaListener: areaListener,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener,
                kill: true
            });
        }
    }, [lang]);
    return (<>
        {Object.keys(data).length ?
            <>
                <Title data={data.content.title} />
                {contact()}
            </>
            : ""}
    </>);
}

export default About;