import React from 'react';
function StrongPoints({ data }) {
    function resultData() {
        const result = data.map((element,index) => {
            return (
                <div className='single-point-container anim-left' key={element.title} style={{transitionDelay:`${index/4}s`, transitionTimingFunction:'cubic-bezier(.2,.8,.5,1.2)'}}>
                    <div className='wrap'>
                        <div className='ico-container'>
                            <img src="files/images/task.svg" alt="" />
                        </div>
                        <h3 className='title bold'>
                            {element.title}
                        </h3>
                    </div>
                    <div className='desc font20'>
                        {element.desc}
                    </div>
                </div>
            )
        });
        return result;
    }

    return (
        <section className='strong-points'>
              <div className='back-container'>
                    <img src="files/images/back-1.webp" alt="" />
                </div>
            <div className='custom-grid'>              
                {resultData()}
            </div>
        </section>
    );
}

export default StrongPoints;