import React, { useState, useContext, useEffect } from 'react';
import { listContent, singleContent, animScroll } from '../functions';
import { createSearchParams, useNavigate } from "react-router-dom";

import AppContext from '../AppContext';

function SingleBox({ activeCat, animation }) {
    const { lang , setHomeSliderRender } = useContext(AppContext);
    const [projects, setProjects] = useState({});
    const [data, setData] = useState({});
    const [firstDataRender , setFirstDataRender] = useState('false');
    const navigate = useNavigate();
    const getProjects = async () => {
        setProjects(await listContent({ lang: lang, type: 'project', order: 'date', term: activeCat }));
    }
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'page', id: 1 }));
    }
    const handleClick = (id) => {
        const objPath = {
            id: id
        }
        navigate({
            pathname: "/single",
            search: createSearchParams(objPath).toString()
        });
        const menu = document.querySelector("#menu");
        const header = document.querySelector(".header");
        setTimeout(() => {
            window.scrollTo(0, menu.offsetTop - header.clientHeight);
        }, 400);
    }
    const allProjects = () => {
        let result = "";
        if (Object.keys(projects).length > 0) {           
            result = projects.map((element, index) => {
                let addClass = "";
                if (index ===0){
                    addClass = 'current-el';
                } 
                let expr = ((element.content.html).replace(/<[a-zA-Z"'= -:._/]*>/g, ""));
                return (
                    <div className={`single-project ${addClass} ${animation ? 'anim-project' : ""}`} key={element.name} onClick={() => handleClick(element.id)}>
                        <div className='cont-img'><img src={`${element.thumbnail}`} alt="" /></div>
                        <div className='desc-container'>
                            <div className='title-project font26 medium'>{element.name}</div>
                            <div className='content-project'><span dangerouslySetInnerHTML={{ __html: expr }}></span></div>
                            <div className='plus-button'><div className='button-in'>{data.content.hasOwnProperty('button') ? data.content.button : ""}</div></div>
                        </div>
                    </div>
                )
            })
        }
        return result;
    }
    useEffect(() => {    
        if (!!firstDataRender && Object.keys(projects).length){            
            setFirstDataRender(true);
            setHomeSliderRender(true);
        }
        const areaListener = new AbortController();
        animScroll({
            onlyOnce: false,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-project'],
            changeClass: '.fade',
            areaListener: areaListener,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener,
                kill: true
            });
            setHomeSliderRender(false);
        }
    }, [projects]);
    useEffect(() => {
        getProjects();
    }, [lang, activeCat])
    useEffect(() => {
        getData();
    }, [lang]);  
    return (
        <>
            {(Object.keys(projects).length && Object.keys(data).length) ?
                allProjects()
                : ""}
        </>
    );
}

export default SingleBox;