const API = './files/data.json'
function animScroll({
  padding = true,
  visible = 0,
  onlyOnce = false,
  classArray = ['.animLeft', '.animRight', '.animTop', '.animBottom', '.animCenter'],
  changeClass = '.animStart',
  update = false,
  areaListener = "",
  kill = false
}) {
  // setTimeout(()=>{
  if (visible < 0) {
    visible = 0;
  };
  if (visible > 0.8) {
    visible = 0.8;
  };
  changeClass = changeClass.replace('.', '');
  let load = true;
  function checkScroll() {
    const table_result = [];    
    let windowHeight = Number(window.innerHeight);
    if (classArray.length) {
      classArray.forEach((element) => {
        if (element[0] === '.') {
          const table_class = [...document.querySelectorAll(element)];         
          table_class.forEach((part) => {
            part.dataset.elementPaddingHeight = Math.ceil(Number(part.getBoundingClientRect().height));
            part.dataset.elementHeight = Math.ceil(Number(part.getBoundingClientRect().height) -
              Number(window.getComputedStyle(part).paddingTop.slice(0, window.getComputedStyle(part).paddingTop.length - 2)) -
              Number(window.getComputedStyle(part).paddingBottom.slice(0, window.getComputedStyle(part).paddingBottom.length - 2)));
          });
          table_result.push(...table_class);
        }
      });
    }
    load = true;
    let view = 0;
    let viewOut = 0;
    let heightToCheckIN = 0;
    let heightToCheckOUT = 0;    
    table_result.forEach((element) => {
      const elementY = Number(element.getBoundingClientRect().y);
      const elementPaddingHeight = Number(element.dataset.elementPaddingHeight);
      const elementHeight = Number(element.dataset.elementHeight);
      if (padding) {
        heightToCheckIN = elementY;
        heightToCheckOUT = elementY + elementPaddingHeight;
        view = Math.floor(elementPaddingHeight * visible);
        // view = Math.floor( windowHeight * visible);         
      } else {
        heightToCheckIN = elementY + (elementPaddingHeight - elementHeight);
        heightToCheckOUT = elementY + (elementPaddingHeight - elementHeight) + elementHeight;
        // view = Math.floor(elementHeight * visible);  
        view = Math.floor(windowHeight * visible);
      }
      if (windowHeight - heightToCheckIN > view && heightToCheckOUT > viewOut) {
        element.classList.add(changeClass);
      } else {
        if (!onlyOnce) {
          element.classList.remove(changeClass);
        }
      }
      if (!onlyOnce) {
        if (heightToCheckOUT < viewOut) {
          element.classList.remove(changeClass);
        } else {

        }
      }
    });
  }
  if (!update) {

    function list() {
      if (load) {
        load = false;
        setTimeout(checkScroll, 50);
      }
    }
    if (kill) {
      // document.removeEventListener("scroll", list);
      areaListener.abort();
    } else {
      document.addEventListener("scroll", list, { signal: areaListener.signal });
      window.addEventListener("resize", list, { signal: areaListener.signal });
    }
  }
  setTimeout(() => {
    checkScroll();
  }, 70)

  // }, 50);
}
const listContent = async ({ lang = 'PL', type = "post", order = null, term = "all" }) => {
  // const { lang } = useContext(AppContext);  
  let dataCount = await fetch(API).catch(() => console.log('error'));  
  let result = await dataCount.text();
  try {
    result = JSON.parse(result);
  } catch (err) {
    console.log('err');
    result = 'Wrong data';
  }
  let tableKey = Object.keys(result[lang]);
  let outData = [];
  tableKey.forEach((element) => {
    if (element === type) {
      outData = result[lang][type];      //array of objects
      if (term !== 'all') {
        const termElements = outData.filter((termEl) => {
          return (
            termEl.category === term
          )
        });
        outData = termElements;
      }
      if (order === "date") {        
        outData.sort((a, b) => {
          const date1 = new Date(`${a.date} ${a.time}`);
          const date2 = new Date(`${b.date} ${b.time}`);         
          return date2 - date1;
        });
      }
    }
  }) 
  return outData;
}
const singleContent = async ({ lang = 'PL', type = "post", id = 0 }) => {
  // const { lang } = useContext(AppContext);  
  let dataCount = await fetch(API).catch(() => console.log('error'));
  let result = await dataCount.text();
  try {
    result = JSON.parse(result);
  } catch (err) {
    console.log('err');
    result = 'Wrong data';
  }
  const tableKey = Object.keys(result[lang]);
  let outData = [];
  let resultData = [];
  tableKey.forEach((element) => {
    if (element === type) {
      outData = result[lang][type];
      if (id > 0) {
        resultData = outData.filter((el) => {
          return Number(el.id) === Number(id);
        });
      }
    }
  });
  return resultData[0];
}
const listCategory = async ({ lang = 'PL', type = "post", order = null }) => {
  // const { lang } = useContext(AppContext);  
  let dataCount = await fetch(API).catch(() => console.log('error'));
  let result = await dataCount.text();
  try {
    result = JSON.parse(result);
  } catch (err) {
    console.log('err');
    result = 'Wrong data';
  }
  const tableKey = Object.keys(result[lang]);
  let outData = [];
  tableKey.forEach((element) => {
    if (element === type) {
      const tData = result[lang][type];
      tData.forEach((el) => {
        if (!outData.includes(el.category)) {
          outData.push(el.category);
        }
      })
      if (order === "name") {
        outData.sort();
      }
    }
  })
  return outData;
}
export { animScroll, listContent, singleContent, listCategory }