import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Projects from './pages/Projects';
import Skills from './pages/Skills';
import SingleProject from './pages/SingleProject';
import Home from './pages/Home';
import Exp from './pages/Exp';
import About from './pages/About'


// const API = 'http://localhost/natacha/wp-json/wp/v2/posts';
// let searhparam = '_fields=id,title';
// +'?'+searhparam

function MainMenu() {
  return (
    <div className='container'>
      <div className='content'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/skills' element={<Skills/>} />              
          <Route path='/single' element={<SingleProject/>} />              
          <Route path='/exp' element={<Exp/>} />              
          <Route path='/about' element={<About/>} />              
          {/* <Route path="*" element={<NotFound />}/> */}
        </Routes>
      </div>
    </div>
  );
}

export default MainMenu;