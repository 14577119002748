import React from 'react';

function Quote({ data }) {
    function uniqueId(){
        return(
            Math.random().toString(16).slice(2)
        )
    }
    function splitQuote(){
        const table = data.content.quote.split(" ");
        const result =  table.map(element => {
            let number = (Math.random() * 1).toFixed(2);
            return(
                <span className='anim-quote' style={{transitionDelay:`${number}s`}} key={uniqueId()}>
                    {element + " "}
                </span>
            )
        });
        return result;
    }
    return (
        <h2 className='section-quote font48 medium'>
            <div className='grid'>
                <div className='quote'>
                    {splitQuote()}
                </div>
            </div>
        </h2>);
}

export default Quote;