import React, { useContext, useState, useEffect } from 'react';
import { singleContent, animScroll } from '../functions';
import Title from './TitleContent';
import AppContext from '../AppContext';
import TextLink from './TextLink';
import SliderTech from './SliderTech';

function Exp() {
    const { lang } = useContext(AppContext);
    const [data, setData] = useState({});
    const [dataHome, setDataHome] = useState({});
    const getData = async () => {
        setData(await singleContent({ lang: lang, type: 'page', id: 3 }));
    }
    const getDataHome = async () => {
        setDataHome(await singleContent({ lang: lang, type: 'page', id: 5 }));
    }
    useEffect(() => {
        getData();
        getDataHome();
        const areaListener = new AbortController();
        animScroll({
            onlyOnce: true,
            padding: false,
            visible: 0.05,
            classArray: ['.anim-center'],
            changeClass: '.fade',
            areaListener: areaListener,
            kill: false
        });
        return () => {
            animScroll({
                areaListener: areaListener,
                kill: true
            });
        }
    }, [lang]);
    return (
        <>
            {Object.keys(data).length ?
                <>
                    <Title data={data.name} />
                    <TextLink data={data.content.link1} />
                    <Title data={data.content.sliderTitle} />
                    {Object.keys(dataHome).length ?
                        <SliderTech data={dataHome} /> : ""
                    }
                </>
                : ""}
        </>
    );
}

export default Exp;