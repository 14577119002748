import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { listContent } from './functions';
import AppContext from './AppContext';
// import { HashLink as Link } from 'react-router-hash-link';


function Navigation() {
    let load = true;
    const { lang, setLang } = useContext(AppContext);
    const [classItem, setClassItem] = useState('');
    const [deg, setDeg] = useState(0);
    const [data, setData] = useState([]);
    const getData = async () => {
        setData(await listContent({ lang: lang, type: 'page' }));
    }
    function chandleclickScroll() {
        window.scrollTo(0, 0);
    }
    function changeLang() {
        if (lang === 'PL') {
            setLang('EN');
            document.documentElement.setAttribute("lang", 'en');
        } else {
            setLang('PL');
            document.documentElement.setAttribute("lang", 'pl');
        }
    }
    function chandleclickMenu(event) {
        const parent = (event.target).closest('.navlink');
        if (![...parent.classList].includes("active")) {
            const menu = document.querySelector("#menu");
            const header = document.querySelector(".header");
            setTimeout(() => {
                window.scrollTo(0, menu.offsetTop - header.clientHeight);
            }, 400);
        }
    }
    function border() {
        return (
            <>
                <div className='left-vertical'></div>
                <div className='left-horizontal'></div>
                <div className='right-vertical'></div>
                <div className='right-horizontal'></div>
            </>
        );
    }
    function checkScroll() {
        load = true;
        const deg = 1.5;
        const elHeight = Number(document.querySelector('.nav-content').scrollHeight);
        const multiplier = deg / elHeight;
        const scrollY = window.scrollY;
        let outDeg = Number((scrollY * multiplier)).toFixed(2);
        if (outDeg > deg) {
            outDeg = deg
        }
        setDeg(outDeg);
    }
    function parEffect() {
        document.addEventListener('scroll', () => {
            let copyLoad = load;
            if (copyLoad) {
                load = false;
                setTimeout(checkScroll, 5);
            }
        })
    }
    function createContent() {
        let contentResult = data.map((element) => {
            if (element.hasOwnProperty('name') && element.hasOwnProperty('slug') && element.hasOwnProperty('nav')) {
                return (
                    <NavLink to={`/${element.slug}`} className='font36 medium navlink' onClick={chandleclickMenu} key={element.slug}>
                        <div className='element'>
                            <span>{element.name}
                                {border()}
                            </span>
                        </div>
                    </NavLink>
                );
            } else return "";
        });
        return contentResult;
    }
    useEffect(() => {
        getData();
    }, [lang]);
    useEffect(() => {
        setTimeout(() => setClassItem("after-render"), 100);
        parEffect();
    }, []);
    return (
        <>
            {Object.keys(data).length ?
                <>
                    <section className='navigation'>
                        <div className='background-container'>
                            <img className='background' src="files/background.png" alt="" style={{ transform: `perspective(10vw) rotateY(1deg) rotateX(-${deg}deg)` }} />
                        </div>
                        <h1 className='header '>
                            <div className='container'>
                                <NavLink to='/' className={`font48 bold ${classItem}`} onClick={chandleclickScroll}>
                                    <div className='element'>
                                        <span>On DEV
                                            {border()}
                                        </span>
                                    </div>
                                </NavLink>
                                <div className='langButton font36 medium' onClick={changeLang}>
                                    {lang}{border()}
                                </div>
                            </div>
                        </h1>

                        <div className='container'>
                            <div className='nav-content'>
                                {createContent()}
                            </div>

                        </div>
                    </section>
                </>
                : ""}
        </>
    );
}

export default Navigation;