import React, { useRef, useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
function SliderTech({ data }) {
    const [slider, setSlider] = useState([0]);
    const [currentSlide, setCurrentSlide] = useState(0);
    // const [resize, setResize] = useState(true);
    const sliderRef = useRef(null);
    const contRef = useRef(null);
    const handlers = useSwipeable({
        onSwipedLeft: () => handleClickNext(),
        onSwipedRight: () => handleClickPrev(),
        delta: 20,
    });
    function showSlider() {
        const result = data.content.sliderTech.map((element,index ) => {
            return (
                <div className='element-in' key={index+'el-in'}>
                    <div className='img-cont'>
                        <img src={element} alt="" />
                    </div>
                </div>
            )
        });
        return result;
    } 
    function handleClickNext() {
        if (currentSlide >= slider.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    }
    function handleClickPrev() {
        if (currentSlide === 0) {
            setCurrentSlide(slider.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    }
    function sliderStart() {
        let slidrWidth = sliderRef.current.scrollWidth;
        let sliderInWidth = contRef.current.scrollWidth;
        let stop = false;
        let move = 0;
        let maxMove = sliderInWidth - slidrWidth;
        let transform = [0];
        let elements = sliderRef.current.querySelectorAll('.element-in');
        elements.forEach(element => {
            if (!stop) {
                move += element.clientWidth;
                if (move >= maxMove) {
                    move = maxMove;
                    stop = true;
                }
                transform.push(move);
            }
        });
        if (currentSlide > transform.length - 1){
            setCurrentSlide(transform.length - 1);
        }     
        setSlider(transform);
    }
    useEffect(() => {
        if (document.readyState === "complete") {
            setTimeout(() => {
                sliderStart();
            }, 50);
        }
        window.addEventListener('load', sliderStart);
        return () => document.removeEventListener('load', sliderStart);
    }, []);
    useEffect(()=>{
        const areaListener = new AbortController();
        let resize = true
        window.addEventListener('resize',()=>{
            if (resize) {
                sliderStart();               
                resize = false;
                setTimeout(()=>{
                    resize = true;
                },50)
            }
        },{ signal: areaListener.signal });
        return () => {
            areaListener.abort();
        }
    },[])
    return (
        <section className='slider-tech'>
            <div className='custom-slider-tech-container' ref={sliderRef}>
                <div className={`arr-prev`} onClick={handleClickPrev}> <img src="files/images/arr-left.svg" alt="" /> </div>
                <div className='slider-wrap' {...handlers}>
                    <div className='container-slider-in' ref={contRef} style={{ transform: `translateX(-${slider[currentSlide]}px)` }}>
                        {showSlider()}
                    </div>
                </div>
                <div className={`arr-next`} onClick={handleClickNext}><img src="files/images/arr-right.svg" alt="" /></div>
            </div>
        </section>
    );
}

export default SliderTech;